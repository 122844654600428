import React, { useContext, useEffect, useState } from 'react';
import 'chart.js/auto';
import { Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import StatisticsChart from '../admin/statistics/StatisticsChart';
import "./StatisticsPageView.scss";
import StatisticsFilter, { Filter } from './StatisticsFilter';
import { ChartDTO, ProjectType, RenderType } from '../../openapi/requests';
import { useClientServiceGetClient, useSceneServiceGetScene, useStatisticsServiceGetStatistics, useTemplateServiceGetTemplate, useUserServiceGetUserClient, useUserServiceGetUserClient1, useUserServiceGetUserClients } from '../../openapi/queries';
import { ConfigContext } from '../../contexts/ConfigContext';
import { endOfWeek } from "date-fns";


const StatisticsPageView: React.FC = () => {

    const { currentClient } = useContext(ConfigContext);

    const [selectedStartDate, setSelectedStartDate] = useState<Date>();
    const [selectedEndDate, setSelectedEndDate] = useState<Date>();
    const [selectedInterval, setSelectedInterval] = useState<string>("week");
    const [selectedScene, setSelectedScene] = useState<number>();
    const [selectedRenderType, setSelectedRenderType] = useState<RenderType>();
    const [selectedProjectType, setSelectedProjectType] = useState<ProjectType>();
    const [selectedUserId, setSelectedUserId] = useState<number>();

    const [chartsData, setChartsData] = useState<ChartDTO[]>([]);
    const [scenesData, setScenesData] = useState<DefaultOptionType[]>([]);
    const [usersData, setUsersData] = useState<DefaultOptionType[]>([]);
    const [filters, setFilters] = useState<Filter[]>([]);

    const { data: statistics, isLoading } = useStatisticsServiceGetStatistics({ startDate: selectedStartDate?.toDateString(), endDate: selectedEndDate?.toDateString(), dateInterval: selectedInterval, projectType: selectedProjectType, renderType: selectedRenderType, sceneId: selectedScene, userId: selectedUserId, clientId: currentClient.id });
    const { data: scenes } = useSceneServiceGetScene({ filter: `IsEnabled eq true` });
    const { data: templates } = useTemplateServiceGetTemplate({expand: 'scene'});
    const { data: users } = useUserServiceGetUserClient();

    const dateIntervalOptions = [
        { label: "Week", value: "week" },
        { label: "Month", value: "month" },
        { label: "Quarter", value: "quarter" },
        { label: "Year", value: "year" }
    ];

    const renderTypeOptions = [
        { label: "Preview", value: "preview" },
        { label: "Final", value: "final" },
    ]

    const projectTypeOptions = [
        { label: "In-situ", value: "insitu" },
        { label: "Cutout", value: "cutout" },
    ]

    type StatisticsFilterProperties = {
        name: string,
        property: string,
        type: string,
        items: any;
    }

    let properties = Array<StatisticsFilterProperties>()

    properties = [
        { name: 'Date', property: 'date', type: 'daterange', items: scenes?.value ?? [] },
        { name: 'Interval', property: 'interval', type: 'select', items: dateIntervalOptions },
        { name: 'User', property: 'user', type: 'select', items: usersData ?? [] },
        { name: 'Project Type', property: 'projecttype', type: 'select', items: projectTypeOptions },
        { name: 'Scene', property: 'scene', type: 'select', items: scenesData ?? [] },
        { name: 'Render Type', property: 'rendertype', type: 'select', items: renderTypeOptions }
    ];

    useEffect(() => {

        let dateValues = filters.find(x => x.property === "date");      
        let startDate = new Date('2023-11-01')        
        let endDate = endOfWeek(new Date())

        if (dateValues && dateValues.values.length > 0) {
            startDate = new Date(parseInt(dateValues.values[0]));

            if (dateValues.values[1])
                endDate = new Date(parseInt(dateValues.values[1]));
        }
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);

        let userValue = filters.find(x => x.property === "user");
        let userId = null;
        if (userValue) {
            userId = userValue.values[0].value;
        }
        setSelectedUserId(userId);

        let intervalValue = filters.find(x => x.property === "interval");
        let interval = "week";
        if (intervalValue) {
            interval = intervalValue.values[0].value;
        }
        setSelectedInterval(interval);

        let sceneValue = filters.find(x => x.property === "scene");
        let scene = undefined;
        if (sceneValue) {
            scene = (sceneValue.values[0].value !== "") ? parseInt(sceneValue.values[0].value) : undefined;
        }
        setSelectedScene(scene)

        let renderTypeValue = filters.find(x => x.property === "rendertype");
        let renderType: RenderType | undefined = undefined;
        if (renderTypeValue) {
            renderType = renderTypeValue.values[0].value;
        }
        setSelectedRenderType(renderType)

        let projectTypeValue = filters.find(x => x.property === "projecttype");
        let projectType: ProjectType | undefined = undefined;
        if (projectTypeValue) {
            projectType = projectTypeValue.values[0].value;
        }
        setSelectedProjectType(projectType)

        // var statistics = StatisticsService.getStatistics(startDate.toDateString(), endDate.toDateString(), userId, interval, scene, renderType, projectType);
        //   var scenes = SceneService.getScene(undefined, undefined, undefined, `IsEnabled eq true`, undefined, undefined, undefined);
        //  var templates = TemplateService.getTemplate();
        //  var users = UserService.getUserClients(clients.value.map(x => x.id));

        /*  Promise.all([statistics, scenes, templates, users]).then((resp) => {
  
              var statisticsData = resp[0];
              var sceneOptionsData = resp[1].value
              var templateOptionsData = resp[2].value;
              var usersOptionsData = resp[3];
  
              if (projectType !== undefined) {
                  var productTypeValue = (projectType?.toString() === "cutout") ? "cutout" : "generic_3d_scene";
  
                  var sceneIds = templateOptionsData.filter(x => x.editorModule === productTypeValue).map(x => x.scene.id);
                  sceneOptionsData = sceneOptionsData.filter(x => sceneIds.includes(x.id));
              }
              
              const sceneOptions = sceneOptionsData.map(x => ({ label: x.title, value: x.id.toString() }));
              const usersOptions = usersOptionsData.map(x => ({ label: x.username, value: x.id.toString() }));
  
             // setChartsData(statisticsData);
             // setScenesData(sceneOptions);
           //   setUsers(usersOptions);           
             // setLoading(false);
          });*/

        let sceneOptionsData = scenes?.value ?? []

        if (projectType !== undefined) {
            var productTypeValue = (projectType?.toString() === "cutout") ? "cutout" : "generic_3d_scene";

            var sceneIds = templates.value.filter(x => x.editorModule === productTypeValue).map(x => x.scene.id);
            sceneOptionsData = sceneOptionsData.filter(x => sceneIds.includes(x.id));
        }

        setChartsData(statistics?.value ?? []);
        setScenesData(sceneOptionsData.map(x => ({ label: x.title, value: x.id.toString() })));
        setUsersData(users?.map(x => ({ label: x.username, value: x.id.toString() })) ?? []);
        //  setLoading(false);

    }, [filters, statistics, scenes, users, templates])

    /*  useEffect(() => {
          reload();
      }, [reload]);*/

    const onChange = (filters: Filter[]) => {

        var updatedFilters = filters.filter(x => x.values.length > 0);
        setFilters([...updatedFilters]);

        //   setLoading(true);
    }


    return (
        <div className='statistics-page-view'>

            <StatisticsFilter isOpen={true} filters={filters} onChange={onChange} properties={properties} />

            <Spin spinning={isLoading}>
                <div className='statistics-page-charts'>
                    {chartsData.map(chart => (
                        <>
                            {chart.data.map(chartData => (
                                <div>
                                    <StatisticsChart labels={chart.labels} chartData={chartData} color="#adadad" />
                                </div>
                            ))}
                        </>
                    ))}
                </div>
            </Spin>
        </div>
    )
}

export default StatisticsPageView;