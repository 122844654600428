import { useCallback, useEffect, useMemo, useRef } from "react";
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import Select, { components, OptionProps, StylesConfig } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDebouncedCallback } from "use-debounce";
import "../layout/elements/Filter.scss";
import { Filter, InputProps, Option, Property, Props } from "../../types/types";

const colourStyles: StylesConfig<Option, true> = {
    control: (styles) => ({
        ...styles,
        borderRadius: '0',
        fontSize: '12px',
        minHeight: '32px'
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0px 8px 0px 0px'
    }),
    clearIndicator: (styles) => ({
        ...styles,
        padding: '0px 0px 0px 0px'
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '12px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        color: (isSelected) ? '#FFF' : (isFocused) ? '#FFF' : '#000',
        backgroundColor: (isSelected) ? '#000' : (isFocused) ? 'rgba(0,0,0,.26)' : '#FFF',
        ':active': {
            ...styles[':active'],
            color: (isSelected) ? '#FFF' : (isFocused) ? '#FFF' : '#000',
            backgroundColor: (isSelected) ? '#000' : (isFocused) ? 'rgba(0,0,0,.26)' : '#FFF',
        }
    }),

};

const OptionComponent = (props: OptionProps<Option>) => {
    return (
        <components.Option {...props}> <input type="checkbox" checked={props.isSelected} /> {props.children}</components.Option>
    );
};

export const ProjectFilter: React.FC<Props> = ({ properties, onChange, filters, isOpen, items }) => {

    const hasSearch = useMemo(() => {
        return filters.filter(x => x.type === "search").length > 0;
    },[filters]);

    const onFilterAdded = useCallback((filter: Filter) => {
        onChange([...filters.filter(e => e.property !== filter.property), filter]);
    }, [filters, onChange]);

    const clearAll = useCallback(() => {
        onChange([]);
    }, [onChange]);

    const onFilterRemove = useCallback((filter: Filter, value: string | number | boolean) => {
        onChange([...filters.filter(e => e.property !== filter.property)]);
    }, [filters, onChange]);

    const renderInput = useCallback((prop: Property) => {
        switch (prop.type) {
            case "select": {
                return <ProjectFilterInput key={prop.name}
                    name={prop.name}
                    property={prop.property}
                    onChange={onFilterAdded}
                    mapFn={prop.mapFn}
                    itemsOverride={prop.itemsOverride}
                    items={items}                   
                    filter={filters.find(e => e.property === prop.property) ?? { name: prop.name, property: prop.property, values: [], type: prop.type }} />
            }
            case "search": {
                return <ProjectSearchInput key={prop.name}
                    name={prop.name}
                    property={prop.property}
                    onChange={onFilterAdded}
                    itemsOverride={prop.itemsOverride}
                    hasSearch={hasSearch}
                    items={items}
                    filter={filters.find(e => e.property === prop.property) ?? { name: prop.name, property: prop.property, values: [], type: prop.type }} />
            }
            case "daterange": {
                return <ProjectDateRangeInput key={prop.name}
                    name={prop.name}
                    property={prop.property}
                    onChange={onFilterAdded}
                    mapFn={prop.mapFn}
                    itemsOverride={prop.itemsOverride}
                    items={items}               
                    filter={filters.find(e => e.property === prop.property) ?? { name: prop.name, property: prop.property, values: [], type: prop.type }} />
            }
        }
    }, [filters, items, onFilterAdded, hasSearch])

    const ProjectSearchInput: React.FC<InputProps> = ({ name, property, hasSearch, onChange, itemsOverride, items, filter }) => {

        const elementRef = useRef<HTMLInputElement>(null);
     
        useEffect(() => {
            if (!hasSearch && elementRef.current) {
                elementRef.current.value = "";
            }
        }, [hasSearch])

        const handleChange = useCallback((e: any) => {
            var values = (e.target.value !== "") ? [e.target.value] : [];

            onChange({
                property: property,
                name: name,
                values: values,
                type: filter.type
            });
        }, [onChange, property, name, filter]);

        const onDebouncedChange = useDebouncedCallback(handleChange, 500);

        return (
            <div className='filter-input'>
                <div className='text-input search'>
                    <input type="search" ref={elementRef} placeholder={`${name}...`} onChange={(e) => onDebouncedChange(e)} />
                </div>
            </div>
        )
    }

    const ProjectDateRangeInput: React.FC<InputProps> = ({ name, property, onChange, itemsOverride, items, filter, mapFn }) => {

        const onFromDateChange = (date: Date) => {

            let dateValues: string[] = [];
            if (date) {
                dateValues = [date.getTime().toString()]
            }

            if (filter.values[1]) {
                dateValues = [...dateValues, new Date(parseInt(filter.values[1].toString())).getTime().toString()]
            }

            onChange({ name: name, property: property, values: dateValues, type: filter.type });
        }

        const onToDateChange = (date: Date) => {

            let dateValues: string[] = [];
            if (date) {
                dateValues = [date.getTime().toString()]
            }

            if (filter.values[0]) {
                dateValues = [new Date(parseInt(filter.values[0].toString())).getTime().toString(), ...dateValues]
            }

            onChange({ name: name, property: property, values: dateValues, type: filter.type });
        }

       /* const minDate = useMemo(() => {
            return new Date(Math.min(...items.map(x => new Date(x[property]).getTime())))
        }, [property, items])

        const maxDate = useMemo(() => {
            return new Date(Math.max(...items.map(x => new Date(x[property]).getTime())))
        }, [property, items])*/

        const selectedFromDate = useMemo(() => {
            return (filter.values[0]) ? new Date(parseInt(filter.values[0].toString())) : null;
        }, [filter])

        const selectedToDate = useMemo(() => {
            return (filter.values[1]) ? new Date(parseInt(filter.values[1].toString())) : null;
        }, [filter])

        return (
            <div className='filter-input date-input'>
                <DatePicker placeholderText="From" dateFormat={"dd.MM.yyyy"} isClearable selected={selectedFromDate} onChange={onFromDateChange} />
                <DatePicker placeholderText="To" isClearable dateFormat={"dd.MM.yyyy"} selected={selectedToDate} onChange={onToDateChange} />
            </div>
        )
    }

    const ProjectFilterInput: React.FC<InputProps> = ({ name, property, onChange, itemsOverride, items, filter, mapFn }) => {

        const options = useMemo(() => {
            let values = itemsOverride || items
                .map(e => e[property] ?? "")
                .filter(e => e !== "");

            values = Array.from(new Set(values));

            if (mapFn) {
                return values.map(e => mapFn(e));
            }

            return values.map(e => ({ value: e, label: e }));
        }, [items, itemsOverride, property, mapFn]);

        const handleChange = useCallback((options: readonly Option[]) => {
            onChange({
                property: property,
                name: name,
                values: options.map(e => e.value),
                type: filter.type,
                mapFn: mapFn
            });
        }, [onChange, property, name, mapFn, filter]);

        return (
            <div className='filter-input'>
                <Select
                    isMulti
                    placeholder={name}
                    components={{ Option: OptionComponent }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={filter.values.map(e => ({ value: e.toString(), label: e.toString() }))}
                    onChange={handleChange}
                    options={options}
                    styles={colourStyles}
                    controlShouldRenderValue={false}
                />
            </div>
        )
    };

    return (
        <div className='filter'>
            <div className='filter-properties'>
                {properties.map(prop =>
                    renderInput(prop)
                )}
            </div>

            <div className='filter-values'>
                {(filters.some(e => e.values.length > 0)) && <div className='clear' onClick={clearAll}>Clear all filters</div>}
                {filters.filter(x => x.property !== "date").map(filter => filter.values.map(value => <div className='filter-value'>{filter.name}: {(filter.mapFn) ? filter.mapFn(value).label : value}<CloseIcon onClick={() => onFilterRemove(filter, value)} /> </div>))}
            </div>
        </div>
    )

}

export default ProjectFilter;