/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OkResult } from '../models/OkResult';
import type { OrderDTO } from '../models/OrderDTO';
import type { ProjectDTO } from '../models/ProjectDTO';
import type { ProjectViewDTO } from '../models/ProjectViewDTO';
import type { ProjectViewDTOODataListResponse } from '../models/ProjectViewDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ProjectViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getProject(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ProjectViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Project',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ProjectDTO Success
     * @throws ApiError
     */
    public static postProject(
requestBody?: ProjectDTO,
): CancelablePromise<ProjectDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Project',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ProjectViewDTO Success
     * @throws ApiError
     */
    public static getProject1(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ProjectViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Project/{key}',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns ProjectDTO Success
     * @throws ApiError
     */
    public static putProject(
key: number,
requestBody?: ProjectDTO,
): CancelablePromise<ProjectDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Project/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProject(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Project/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns OrderDTO Success
     * @throws ApiError
     */
    public static getProjectOrders(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<Array<OrderDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Project/{key}/orders',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ProjectDTO Success
     * @throws ApiError
     */
    public static postProjectCopy(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ProjectDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Project/{key}/copy',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns any Success
     * @throws ApiError
     */
    public static getProjectFiles(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Project/{key}/files',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getProjectPowerpoint(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Project/{key}/powerpoint',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns any Success
     * @throws ApiError
     */
    public static getProjectMoodboard(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Project/{key}/moodboard',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getProjectKingfisherDamSheet(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Project/{key}/kingfisherDAMSheet',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns OkResult Success
     * @throws ApiError
     */
    public static postProjectUploadToKingfisherDam(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<OkResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Project/{key}/uploadToKingfisherDAM',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static postProjectTransferToKingfisherDam(
key: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Project/{key}/transferToKingfisherDAM',
            path: {
                'key': key,
            },
        });
    }

}
