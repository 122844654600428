import format from "date-fns/format";
import { saveAs } from 'file-saver';
import { useCallback, useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router";
import { BASE_PATH } from "../..";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { ReactComponent as ExternalLink } from "../../assets/icons/external_link.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { ProjectStatus, ProjectStatusLabels } from "../../models/enums";
import { OrderDTO, OrderlineFile, OrderlineService, OrderlineViewDTO, ProjectService, ProjectViewDTO, TemplateService, TemplateViewDTO } from "../../openapi/requests";
import IssueReporting from "../layout/IssueReporting";
import "../layout/elements/Lists.scss";
import { Turntable } from "../layout/elements/Turntable";
import "./ProjectDetail.scss";
import { Confirm } from "../layout/Dialog";
import { useOrderlineServicePostOrderOrderlineAddToSolutionizer, useOrderlineServicePostOrderOrderlineRequestManualAdjusments } from "../../openapi/queries";
import { DialogContext } from "../../contexts/DialogContext";
import ReactGA from "react-ga4";

interface Props {
    projectId?: number;
}

const templateCache: TemplateViewDTO[] = [];

export const ProjectDetail: React.FC<Props> = ({ projectId }) => {

    const { hasPermission, hasRole } = useContext(AuthContext);
    const { message, error } = useContext(DialogContext);

    const navigate = useNavigate();
    const [files, setFiles] = useState<ProjectOrderlineFile[]>([]);
    const [project, setProject] = useState<ProjectViewDTO | undefined>();
    const [order, setOrder] = useState<OrderDTO | undefined>();
    const [hasMoodboard, setHasMoodboard] = useState(false);
    const [projectFilesOrdered, setProjectFilesOrdered] = useState(0);
    const [projectFilesProduced, setProjectFilesProduced] = useState(0);
    const [lastUpdate, setLastUpdate] = useState(new Date());
    const [isDownloading, setIsDownloading] = useState(false);
    const { mutateAsync: requestManualAdjusments } = useOrderlineServicePostOrderOrderlineRequestManualAdjusments();
    const { mutateAsync: addToSolutionizer } = useOrderlineServicePostOrderOrderlineAddToSolutionizer();

    const [loading, setLoading] = useState<boolean>(false);

    type ProjectOrderlineFile = {
        orderline: OrderlineViewDTO,
        type: string,
        files: OrderlineFile[]
    }

    const getTemplate = useCallback(async (templateId: number) => {
        let template = templateCache.find(e => e.id === templateId);

        if(!template){
            template = await TemplateService.getTemplate1(templateId);
            templateCache.push(template);
        }

        return template;
    }, []);

    const getProjectFile = useCallback(async (orderline: OrderlineViewDTO) => {
        
        var template = await getTemplate(orderline.templateId);
        var isCutout = template.title.toLowerCase().includes("cutout");
        var isAnimation = template.editorModule.toLowerCase().includes("clip_assembly");
        var isSpin360 = template.templatetype.internalName === "3dsmax_360spin";
        var isClip = orderline.files && orderline.files[0].contentType.startsWith("video");

        let projectFile: ProjectOrderlineFile = { type: "still", files: orderline.files.flat(1), orderline }
        if (isCutout) {
            projectFile.type = "cutout";
        }
        if (isSpin360) {
            projectFile.type = "spin";
        }
        if (isClip) {
            projectFile.type = "clip";
        }
        if (isAnimation) {
            projectFile.type = "animation";
        }

        return projectFile;
    }, [getTemplate]);

    useEffect(() => {
        setFiles([]);
        setProject(undefined);
        setOrder(undefined);
        setLoading(true)

        if (projectId) {
            ReactGA.event('open_dialog', {dialog: 'projectdetails'});

            ProjectService.getProject1(projectId).then(project => {
                setProject(project);

                ProjectService.getProjectOrders(projectId).then(orders => {

                    const sortedOrders = orders.sort((a, b) => {
                        return new Date(b.timestampCreate).getTime() - new Date(a.timestampCreate).getTime();
                    });

                    const lastOrder = sortedOrders[0];
                    setOrder(lastOrder);

                    const getFiles = async () => {
                        let orderLines: OrderlineViewDTO[] = [];
                        let lastDelivery: OrderlineViewDTO[] | undefined = undefined;

                        for (let i = 0; i < sortedOrders.length; i++) {
                            let order = orders[i];

                            let lines = await OrderlineService.getOrderOrderline(order.id);

                            orderLines = [...orderLines, ...lines];

                            if (lastDelivery === undefined && lines.some(e => e.files.length > 0)) {
                                lastDelivery = lines;
                            }

                            if (i === 0) {
                                var template = await TemplateService.getTemplate1(lines[0].templateId);

                                if (template?.editorModule === "cutout" || template?.title.toLowerCase().includes("cutout")) {
                                    setHasMoodboard(false);
                                } else {
                                    setHasMoodboard(true);
                                }

                                setLastUpdate(lines.reduce((i, e) => new Date(e.timestampUpdate) > i ? new Date(e.timestampUpdate) : i, new Date("1900-01-01")))
                            }
                        }

                        setProjectFilesOrdered(orderLines.reduce((i, e) => i + e.files.length, 0));
                        setProjectFilesProduced(orderLines.filter(x => x.status === 20).reduce((i, e) => i + e.files.length, 0));

                        if (lastDelivery) {
                                
                            let files: ProjectOrderlineFile[] = [];

                            for (let i = 0; i < lastDelivery.length; i++) {
                                files.push(await getProjectFile(lastDelivery[i]));
                            }

                            if(!hasPermission("RenderAnimatedCamera")){
                                files = files.filter(e => e.type !== "clip");
                            }
                            if(!hasPermission("RenderVideo")){
                                files = files.filter(e => e.type !== "animation");
                            }

                            setLoading(false);
                            setFiles(files);
                        } else {
                            setLoading(false)
                        }
                    }

                    getFiles();
                });


            })

        }
    }, [projectId, getProjectFile]);

    const onMoodboardClick = () => {
        if (project && (hasPermission("FinalRender") || hasPermission("PriorityRender") || hasPermission("PreviewRender"))) {
            navigate("/create/edit/" + project.id);
        }
    }

    const onDownloadFiles = (file: OrderlineFile) => {
        ReactGA.event('download_project_files', {});

        setIsDownloading(true)

        let baseFileUrl = file.url.match(/.*(\/)/)!;

        fetch(`${BASE_PATH}${baseFileUrl[0]}all`, {
            method: 'get',
            headers: {
                Accept: file.contentType,
                'Content-Type': file.contentType
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
            setIsDownloading(false)
        });
    }

    const onDownloadFile = (file: OrderlineFile) => {
        ReactGA.event('download_project_file', {});
        fetch(`${BASE_PATH}${file.url}`, {
            method: 'get',
            headers: {
                Accept: file.contentType,
                'Content-Type': file.contentType
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob, file.name);
        });
    }

    const onDownloadAll = async (id: number) => {
        ReactGA.event('download_project_files', {});
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Project/${id}/files`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }

    const onDownloadPowerpoint = async () => {
        ReactGA.event('download_project_powerpoint', {});
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Project/${projectId}/powerpoint`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }

    const onRequestManualAdjusments = useCallback((file: ProjectOrderlineFile) => {
        if (order) {
            ReactGA.event('request_manual_adjustments', {});

            requestManualAdjusments({ id: file.orderline.id, key: order.id })
                .then(e => {
                    message("Order moved to workflow", <a href={e.fullCommentURL} target="_blank" rel="noreferrer">Open in workflow <ExternalLink /></a>);
                    setFiles(files.map(f => f.orderline.id === file.orderline.id ? { ...f, orderline: { ...f.orderline, workflowUrl: e.fullCommentURL } } : f));
                }).catch(e => {
                    error("An error occured", e);
                })
        }
    }, [error, files, message, order, requestManualAdjusments]);

    const onAddToSolutionizer = useCallback((file: ProjectOrderlineFile) => {
        if (order) {
            ReactGA.event('add_to_solutionizer', {});

            addToSolutionizer({ id: file.orderline.id, key: order.id })
                .then(e => {
                    message("Order moved to workflow", <a href={e.fullAssetURL} target="_blank" rel="noreferrer">Open in workflow <ExternalLink /></a>);
                }).catch(e => {
                    error("An error occured", e);
                })
        }
    }, [addToSolutionizer, error, message, order]);



    const renderProjectFile = useCallback((projectFile: ProjectOrderlineFile) => {

        let workflowButton: React.ReactNode = null;
        let workflowButton2: React.ReactNode = null;

        if (hasPermission("RequestManualAdjustments")) {
            if (projectFile.orderline.workflowUrl) {
                workflowButton = <a href={projectFile.orderline.workflowUrl} target="_blank" rel="noreferrer">Open in workflow</a>;
            } else {
                workflowButton = <Confirm title="Request manual adjustments" text="This will copy the image to workflow, do you want to continue?" onConfirm={() => onRequestManualAdjusments(projectFile)}>
                    <button className="rounded">Request manual adjustments</button>
                </Confirm>
            }
        }

        if (hasRole("Cadesign")) {
            if (projectFile.orderline.solutionizerUrl) {
                workflowButton2 = <>
                    <a href={projectFile.orderline.solutionizerUrl} target="_blank" rel="noreferrer">Open in solutionizer</a>
                    <Confirm title="Update in solutionizer" text="This will update the image in workflow, do you want to continue?" onConfirm={() => onAddToSolutionizer(projectFile)}>
                        <button className="rounded">Update in solutionizer</button>
                    </Confirm>
                </>;
            } else {
                workflowButton2 = <Confirm title="Add to solutionizer" text="This will copy the image to workflow, do you want to continue?" onConfirm={() => onAddToSolutionizer(projectFile)}>
                    <button className="rounded">Add to solutionizer</button>
                </Confirm>
            }
        }

        switch (projectFile.type) {
            case "spin":
                return <>
                    <div className="project-images-list-img no-transition">
                        <Turntable sensitivity={0.3} images={projectFile.files.map(file => `${BASE_PATH}${file.url + "/thumbnail/800/png"}`)} />
                    </div>
                    <div className="project-images-list-name">
                        <span>{projectFile.files[0].name}</span>
                    </div>
                    <div className="project-images-list-actions">
                        {workflowButton}
                        {workflowButton2}
                        <button onClick={(e) => onDownloadFiles(projectFile.files[0])}>
                            {isDownloading ? <div className="loading"><Loading /></div> : <Download />}
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(projectFile.files[0].timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>
            case "animation":
            case "clip":
                let file = projectFile.files[0];
                return <>
                    <div className="project-images-list-img">
                        <video src={`${BASE_PATH}${file.url}`} controls />
                    </div>
                    <div className="project-images-list-name">
                        <span>{projectFile.files[0].name}</span>
                    </div>
                    <div className="project-images-list-actions">
                        {workflowButton}
                        {workflowButton2}
                        <button onClick={(e) => onDownloadFiles(projectFile.files[0])}>
                            {isDownloading ? <div className="loading"><Loading /></div> : <Download />}
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(projectFile.files[0].timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>
            case "cutout":
            default: {
                let file = projectFile.files[0];

                return <>
                    <div className="project-images-list-img">
                        <LazyLoadImage src={`${BASE_PATH}${file.url + "/thumbnail/800/png"}`} alt={file.name} />
                    </div>
                    <div className="project-images-list-name">
                        <span>{file.name}</span>
                    </div>
                    <div className="project-images-list-actions">
                        {workflowButton}
                        {workflowButton2}
                        <button onClick={(e) => onDownloadFile(file)}>
                            <Download />
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(file.timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>

            }
        }
    }, [hasPermission, hasRole, isDownloading, onAddToSolutionizer, onRequestManualAdjusments])

    return (
        <div className="project-detail">
            <div className="project-detail-header">
                <h1>{project?.name}</h1>
            </div>

            <div className="project-content-wrapper">
                <div>
                    <div className="info-list">
                        <div className="info-list-header"><span>Basics</span></div>
                        <ul>
                            <li>
                                <span>Name</span>
                                <span>{project?.name}</span>
                            </li>
                            <li>

                                <span>Order By</span>
                                <span>{project?.modifiedUserName}</span>
                            </li>
                            <li>

                                <span>Order Time</span>
                                <span>{format(new Date(order?.timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                            </li>
                            <li>

                                <span>Notifications</span>
                                <span>{order?.notificationRecipients}</span>
                            </li>
                            <li>
                                <span>Status</span>
                                <span>{ProjectStatusLabels[(project?.status ?? 0) as ProjectStatus]}</span>
                            </li>
                            <li>

                                <span>Last Status Update</span>
                                <span>{format(lastUpdate, "dd.MM.yyyy - pp")}</span>
                            </li>
                            <li>

                                <span>Files Ordered</span>
                                <span>{projectFilesOrdered}</span>
                            </li>
                            <li>

                                <span>Files Produced</span>
                                <span>{projectFilesProduced}</span>
                            </li>
                        </ul>
                    </div>

                    {hasMoodboard &&
                        <div className="info-list">
                            <div className="info-list-header">Moodboard</div>
                            <button className="project-info-list-img" onClick={() => onMoodboardClick()}>
                                <div className="project-edit-overlay">
                                    <span>
                                        <Edit />
                                    </span>
                                </div>
                                <img src={BASE_PATH + "/project/" + project?.id + "/moodboard"} alt="Edit project" />
                            </button>
                        </div>
                    }

                </div>

                <div className="info-list project-images-list">
                    <div className="info-list-header">
                        <span>Images created with this scene</span>
                        <span>
                            <IssueReporting type='project' projectId={projectId} className="rounded" />
                            <button className="btn" onClick={() => { onDownloadAll(project?.id ?? 0) }}>Download all</button>
                            <button className="btn" onClick={onDownloadPowerpoint}>Download as powerpoint</button>
                        </span>
                    </div>
                    {loading &&
                        <div className="loading"><Loading /></div>
                    }
                    <ul>
                        {files?.map(file =>
                            <li key={file.files[0].name}>
                                {renderProjectFile(file)}
                            </li>
                        )}
                    </ul>
                    {(files?.length === 0 && !loading) &&
                        <div className="project-images-list-no-images">
                            <span>No available images</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}