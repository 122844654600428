import { useQueries } from "@tanstack/react-query";
import format from "date-fns/format";
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Drawer from 'react-modern-drawer';
import { BASE_PATH } from "../..";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { useProjectFolderServiceGetProjectFolder1, useProjectFolderServiceGetProjectFolderOrders } from "../../openapi/queries";
import { OrderlineFile, OrderlineService, OrderlineViewDTO, TemplateService } from "../../openapi/requests";
import "../layout/elements/Lists.scss";
import { Turntable } from "../layout/elements/Turntable";
import "./ProjectDetail.scss";

interface Props {
    projectFolderId?: number;
    isOpen: boolean;
    onClose: () => void;
}

type ProjectOrderlineFile = {
    type: string,
    files: OrderlineFile[]
}

export const ProjectFolderDetail: React.FC<Props> = ({ projectFolderId, isOpen, onClose }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [files, setFiles] = useState<ProjectOrderlineFile[]>([]);

    const { data: projectFolder, isPending: isProjectFolderPending } = useProjectFolderServiceGetProjectFolder1({ key: projectFolderId ?? 0 }, undefined, { enabled: isOpen && projectFolderId !== undefined });
    //const { data: projects, isPending: isProjectsPending } = useProjectServiceGetProject({ filter: `folderId eq ${projectFolderId}` }, undefined, { enabled: isOpen && projectFolderId !== undefined });
    const { data: orders, isPending: isOrdersPending } = useProjectFolderServiceGetProjectFolderOrders({ key: projectFolderId ?? 0 }, undefined, { enabled: isOpen && projectFolderId !== undefined });

    const loading = isProjectFolderPending || isOrdersPending;

    const combinedOrderlines = useQueries({
        queries: orders?.map(order => (
            { queryKey: ['orderlines', order.id], queryFn: () => OrderlineService.getOrderOrderline(order.id) }
        )) ?? [],
        combine: (results) => {
            return ({
                data: results.map(result => result.data),
                isPending: results.some(result => result.isPending),
            })
        }
    });

    const projectFilesOrdered = useMemo(() => {
        return combinedOrderlines?.data?.flat().reduce((i, e) => i + (e?.files.length ?? 0), 0) ?? 0;
    }, [combinedOrderlines?.data]);

    const projectFilesProduced = useMemo(() => {
        return combinedOrderlines?.data?.flat().filter(e => e?.status === 20).reduce((i, e) => i + (e?.files.length ?? 0), 0) ?? 0;
    }, [combinedOrderlines?.data]);

    const lastUpdate = useMemo(() => {
        return (combinedOrderlines?.data?.flat().filter(e => e !== undefined).reduce((i, e) => new Date(e!.timestampUpdate) > i ? new Date(e!.timestampUpdate) : i, new Date("1900-01-01")))
    }, [combinedOrderlines?.data]);

    const getProjectFile = useCallback(async (orderline: OrderlineViewDTO) => {
        var template = await TemplateService.getTemplate1(orderline.templateId);
        var isCutout = template.title.toLowerCase().includes("cutout");
        var isSpin360 = template.templatetype.internalName === "3dsmax_360spin";
        var isAnimation = orderline.files && orderline.files[0].contentType.startsWith("video");

        let projectFile: ProjectOrderlineFile = { type: "still", files: orderline.files.flat(1) }
        if (isCutout) {
            projectFile.type = "cutout";
        }
        if (isSpin360) {
            projectFile.type = "spin";
        }
        if (isAnimation) {
            projectFile.type = "animation";
        }

        return projectFile;
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (!combinedOrderlines.isPending) {
                var files: ProjectOrderlineFile[] = [];

                for (let i = 0; i < combinedOrderlines.data.length; i++) {
                    const orderlines = combinedOrderlines.data[i];

                    if (orderlines) {
                        for (let j = 0; j < orderlines.length; j++) {
                            const line = orderlines[j];

                            if (line.files.length) {
                                files.push(await getProjectFile(line));
                            }
                        }
                    }
                }
               
                if (files.length > 0)
                    setFiles(files);
            }
        }
        fetchData();
    }, [combinedOrderlines.data, combinedOrderlines.isPending, getProjectFile]);

    const onDownloadFiles = (file: OrderlineFile) => {

        setIsDownloading(true)

        let baseFileUrl = file.url.match(/.*(\/)/)!;

        fetch(`${BASE_PATH}${baseFileUrl[0]}all`, {
            method: 'get',
            headers: {
                Accept: file.contentType,
                'Content-Type': file.contentType
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
            setIsDownloading(false)
        });
    }

    const onDownloadFile = (file: OrderlineFile) => {
        fetch(`${BASE_PATH}${file.url}`, {
            method: 'get',
            headers: {
                Accept: file.contentType,
                'Content-Type': file.contentType
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob, file.name);
        });
    }



    const onDownloadAll = async (id: number) => {
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Projectfolder/${id}/files`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }

    const onDownloadPowerpoint = async () => {
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Projectfolder/${projectFolderId}/powerpoint`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }

    const renderProjectFile = useCallback((projectFile: ProjectOrderlineFile) => {
        switch (projectFile.type) {
            case "spin":
                return <>
                    <div className="project-images-list-img no-transition">
                        <Turntable sensitivity={0.3} images={projectFile.files.map(file => `${BASE_PATH}${file.url + "/thumbnail/800/png"}`)} />
                    </div>
                    <div className="project-images-list-name">
                        <span>{projectFile.files[0].name}</span>
                        <button onClick={(e) => onDownloadFiles(projectFile.files[0])}>
                            {isDownloading ? <div className="loading"><Loading /></div> : <Download />}
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(projectFile.files[0].timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>
            case "animation":
                let file = projectFile.files[0];
                return <>
                    <div className="project-images-list-img">
                        <video src={`${BASE_PATH}${file.url}`} controls />
                    </div>
                    <div className="project-images-list-name">
                        <span>{projectFile.files[0].name}</span>
                        <button onClick={(e) => onDownloadFiles(projectFile.files[0])}>
                            {isDownloading ? <div className="loading"><Loading /></div> : <Download />}
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(projectFile.files[0].timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>
            case "cutout":
            default: {
                let file = projectFile.files[0];

                return <>
                    <div className="project-images-list-img">
                        <LazyLoadImage src={`${BASE_PATH}${file.url + "/thumbnail/800/png"}`} alt={file.name} />
                    </div>
                    <div className="project-images-list-name">
                        <span>{file.name}</span>
                        <button onClick={(e) => onDownloadFile(file)}>
                            <Download />
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(file.timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>

            }
        }
    }, [isDownloading])

    return (
        <Drawer className="project-detail-overlay" enableOverlay={false} open={isOpen} size={"100%"} direction="bottom" onClose={onClose}>
            <div className="project-detail-overlay-header">
                <button onClick={onClose}>
                    <svg width="56" height="28" viewBox="0 0 56 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="55" height="27" rx="13.5" fill="#1A1A1A" stroke="white" />
                        <path d="M30.5 10.5L23.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M23.5 10.5L30.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            <div className="project-detail-overlay-content">
                <div className="project-detail">
                    <div className="project-detail-header">
                        <h1>{projectFolder?.name}</h1>

                    </div>

                    <div className="project-content-wrapper">
                        <div>
                            <div className="info-list">
                                <div className="info-list-header"><span>Basics</span></div>
                                <ul>
                                    <li>
                                        <span>Name</span>
                                        <span>{projectFolder?.name}</span>
                                    </li>
                                    <li>

                                        <span>Order By</span>
                                        <span>{projectFolder?.modifiedUserName}</span>
                                    </li>
                                    <li>

                                        <span>Order Time</span>
                                        <span>{format(new Date(projectFolder?.timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                                    </li>
                                    <li>

                                        <span>Last Status Update</span>
                                        <span>{format(lastUpdate, "dd.MM.yyyy - pp")}</span>
                                    </li>
                                    <li>

                                        <span>Files Ordered</span>
                                        <span>{projectFilesOrdered}</span>
                                    </li>
                                    <li>

                                        <span>Files Produced</span>
                                        <span>{projectFilesProduced}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="info-list project-images-list">
                            <div className="info-list-header">
                                <span>Images created with this scene</span>
                                <span>
                                    <button className="btn" onClick={() => { onDownloadAll(projectFolderId ?? 0) }}>Download all</button>
                                    <button className="btn" onClick={onDownloadPowerpoint}>Download as powerpoint</button>
                                </span>
                            </div>
                            {loading &&
                                <div className="loading"><Loading /></div>
                            }
                            <ul>
                                {files?.map(file =>
                                    <li key={file.files[0].name}>
                                        {renderProjectFile(file)}
                                    </li>
                                )}
                            </ul>
                            {(files?.length === 0 && !loading) &&
                                <div className="project-images-list-no-images">
                                    <span>No available images</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>




    )
}