import { Checkbox, Drawer, Input, message, Select, Space, Image, Empty, Timeline, Alert, Col, Descriptions, Modal, Row, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'antd';
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/es/upload';
import ImageFileUpload from '../Inputs/ImageFileUpload';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTimeago from 'react-timeago';
import { Typography } from 'antd';
import { diff, flattenChangeset } from 'json-diff-ts';
import { useInternalClientServiceGetInternalClient, useInternalTagServiceGetInternalTag, useModifierServiceGetModifierApprovalHistory, useModifierServiceGetModifierQAimages, useModifierServicePutModifier } from '../../../openapi/queries';
import { AssetApprovalHistoryDTO, ModifierViewDTO } from '../../../openapi/requests';
import { BASE_PATH } from '../../..';
import { AssetApprovalStatus, AssetApprovalStatusLabels } from '../../../models/enums';
import ApproveModifier from './ApproveModifier';

const { Text, Title } = Typography;

const EditModel: React.FC<{ modifier?: ModifierViewDTO, isOpen: boolean, onClose: () => void }> = ({ modifier, isOpen, onClose }) => {
    const { data: clients } = useInternalClientServiceGetInternalClient({}, undefined, { enabled: isOpen });
    const { data: alltags } = useInternalTagServiceGetInternalTag({}, undefined, { enabled: isOpen });
    const { data: qaHistory } = useModifierServiceGetModifierApprovalHistory({ key: modifier?.id ?? 0 }, undefined, { enabled: isOpen && modifier !== undefined });
    const { data: qaImages } = useModifierServiceGetModifierQAimages({ key: modifier?.id ?? 0 }, undefined, { enabled: isOpen && modifier !== undefined });
    const { mutateAsync, isPending } = useModifierServicePutModifier();
    const [approvalOpen, setApprovalOpen] = useState(false);

    const tags = useMemo(() => {
        if (alltags) {
            const tags = Array.from(new Set(alltags.value.map(e => e.value)));
            tags.sort();
            return tags;
        }
        return [];
    }, [alltags]);

    const [form] = Form.useForm<FormData>();
    const [messageApi, contextHolder] = message.useMessage();

    const [modifierImage, setModifierImage] = useState("")

    const clientId = Form.useWatch('clientId', form);
    const filename = Form.useWatch('filename', form);

    const assetFilePath = useMemo(() => {
        if (clients) {
            let client = clients.value.find(e => e.id === clientId);
            if (client) {
                return client.maxModifiersPath + "\\" + filename;
            }
        }
        return "";
    }, [clientId, clients, filename]);

    const onSubmit = () => {
        form.submit();
    }

    const getThumbnailPath = useCallback(() => {
        return `${BASE_PATH}/modifier/${modifier?.id}/thumbnail?rnd=` + Math.floor(Math.random() * 999999999)
    }, [modifier]);

    const onUpload = (info: UploadChangeParam) => {
        if (modifier) {
            setModifierImage(getThumbnailPath())
        }
    }

    const onFinish = () => {
        if (modifier && modifier.id) {
            const values = form.getFieldsValue(true) as ModifierViewDTO;

            mutateAsync({ key: modifier.id, requestBody: values })
                .then(() => {
                    messageApi.success("Modifier updated");
                    onClose();
                }).catch(reason => {
                    messageApi.error(JSON.stringify(reason));
                });
        }
    };

    useEffect(() => {
        if (modifier) {
            setModifierImage(getThumbnailPath())
            form.setFieldsValue(modifier);
        }
    }, [modifier, form, getThumbnailPath]);

    return (
        <>
            {contextHolder}

            <Drawer
                title={`Edit ${modifier?.name}`}
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item label="Thumbnail">
                        <Space direction='vertical'>
                            <ImageFileUpload title="Upload thumbnail" action={`${BASE_PATH}/modifier/${modifier?.id}/image`} onUpload={onUpload} />
                            <Image preview={false} width={300} src={modifierImage} />
                        </Space>
                    </Form.Item>

                    <Form.Item label="QA Images">
                        <Space wrap>
                            {qaImages?.map(img => <Image key={img} placeholder="" width={150} src={BASE_PATH + img} />)}
                            {qaImages?.length === 0 && <Empty />}
                        </Space>
                    </Form.Item>

                    <Form.Item name="isEnabled" valuePropName="checked">
                        <Checkbox>Enabled</Checkbox>
                    </Form.Item>

                    <Form.Item noStyle>
                        <Space>
                            <p>Status: <b>{AssetApprovalStatusLabels[(modifier?.approvalStatus ?? 0) as AssetApprovalStatus]}</b></p>
                            <Button type='primary' onClick={() => setApprovalOpen(true)}>Change</Button>
                        </Space>

                        <Timeline items={[
                            {
                                color: 'blue',
                                children: (<>
                                    Modifier created <ReactTimeago date={modifier?.timestampCreate ?? ""} />
                                </>)
                            },
                            ...(qaHistory || []).map(e => ({
                                color: e.newStatus === AssetApprovalStatus.Approved ? 'green' : (e.newStatus === AssetApprovalStatus.Rejected ? 'red' : 'blue'),
                                children: (<>
                                    Status changed from <b>{AssetApprovalStatusLabels[e.oldStatus as AssetApprovalStatus]}</b> to <b>{AssetApprovalStatusLabels[e.newStatus as AssetApprovalStatus]}</b> {' '}
                                    <ReactTimeago date={e.timestamp} />
                                    <ModifierHistoryDetailsButton item={e} modifier={modifier} />
                                    {e.comment && <>
                                        <br />
                                        <Text code>{e.comment}</Text>
                                    </>}
                                </>)
                            }))]} />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Filename">
                        <Space.Compact style={{ width: '100%' }}>
                            <Form.Item rules={[{ required: true }]} name='filename' style={{ flexGrow: 1 }} noStyle>
                                <Input />
                            </Form.Item>
                            <CopyToClipboard text={assetFilePath}>
                                <Button type="default">Copy full path</Button>
                            </CopyToClipboard>
                        </Space.Compact>
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='name' label="Name">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='sourceObject' label="Source object">
                        <Input />
                    </Form.Item>

                    <Form.Item label='Values'>
                        <Form.List name="values">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'variableName']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Variable name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Text value" />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'isNumeric']}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>is numeric</Checkbox>
                                            </Form.Item>

                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add value
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item name='tags' label="Tags">
                        <Select mode='tags' options={tags.map(e => ({ label: e, value: e }))} />
                    </Form.Item>

                    <Form.Item label='Metadata'>
                        <Form.List name="metadata">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="value" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add metadata
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Drawer>

            <ApproveModifier isOpen={approvalOpen} onClose={() => setApprovalOpen(false)} modifiers={modifier === undefined ? [] : [modifier]} hideEditor  />
        </>
    );
};


export const ModifierHistoryDetailsButton: React.FC<{ modifier?: ModifierViewDTO, item: AssetApprovalHistoryDTO }> = ({ modifier, item }) => {

    const [isOpen, setIsOpen] = useState(false);

    const showModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleOk = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const oldModifier = useMemo(() => {
        if (item.assetData && isOpen) {
            return JSON.parse(item.assetData) as ModifierViewDTO;
        }
        return undefined;
    }, [isOpen, item.assetData]);

    const diffs = useMemo(() => {
        if (item.assetData && isOpen) {
            var oldData = JSON.parse(item.assetData) as ModifierViewDTO;
            let changeset = flattenChangeset(diff(oldData, modifier, { surfaces: 'name', placementpoints: 'name', metadata: 'name' }));

            const ignoredProperties = ["$.approvalStatus", "$.timestampCreate"];
            changeset = changeset.filter(e => !ignoredProperties.includes(e.path));

            return changeset;
        }
        return undefined;
    }, [isOpen, item.assetData, modifier]);

    return (<>
        <Button size='small' type='link' onClick={showModal}>Details</Button>

        <Modal open={isOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
            <p>
                Status changed from <b>{AssetApprovalStatusLabels[item.oldStatus as AssetApprovalStatus]}</b> to <b>{AssetApprovalStatusLabels[item.newStatus as AssetApprovalStatus]}</b> at {item.timestamp} by {item.username ?? "API"}
            </p>
            {item.comment && <p>
                Comment: <br />
                {item.comment}
            </p>}

            <Row>
                <Col span={12}>
                    <Title level={2}>Images</Title>
                    {item.images.map(e => <Image src={BASE_PATH + e} />)}


                    <Title level={2}>Data</Title>

                    {oldModifier &&
                        <Descriptions layout='horizontal' size='small' bordered column={1} items={[
                            {
                                label: 'Status',
                                children: AssetApprovalStatusLabels[(oldModifier?.approvalStatus ?? 0) as AssetApprovalStatus]
                            },
                            {
                                label: 'Title',
                                children: oldModifier?.title
                            },
                            {
                                label: 'Filename',
                                children: oldModifier?.filename
                            },
                            {
                                label: 'SourceObject',
                                children: oldModifier?.sourceObject
                            },
                            {
                                label: 'Name',
                                children: oldModifier?.name
                            },
                            {
                                label: 'Tags',
                                children: oldModifier?.tags?.map(e => <Tag>{e}</Tag>)
                            },
                            {
                                label: 'Meta data',
                                children: <Descriptions size='small' colon layout='horizontal' column={1} items={oldModifier?.metadata?.map(e => ({ label: e.name, children: e.value }))} />
                            },
                            {
                                label: 'Surfaces',
                                children: oldModifier?.values?.map(value => (
                                    <Descriptions style={{ marginBottom: 20 }} labelStyle={{ width: 175 }} bordered size='small' colon layout='horizontal' column={1} items={[
                                        {
                                            label: 'variableName',
                                            children: value.variableName
                                        },
                                        {
                                            label: 'value',
                                            children: value.value
                                        },
                                        {
                                            label: 'isNumeric',
                                            children: value.isNumeric.toString()
                                        },
                                    ]} />
                                ))
                            },
                        ]} />
                    }


                </Col>
                <Col span={12}>
                    <Title>Diff with current data</Title>
                    {diffs?.map(e => <>
                        {e.type === "UPDATE" ? <Alert icon={<EditOutlined />} showIcon type='info' message={e.path} description={<><del>{JSON.stringify(e.oldValue)}</del> {JSON.stringify(e.value)}</>} /> : null}
                        {e.type === "ADD" ? <Alert icon={<PlusOutlined />} showIcon type='success' message={e.path} description={JSON.stringify(e.value)} /> : null}
                        {e.type === "REMOVE" ? <Alert icon={<DeleteOutlined />} showIcon type='error' message={e.path} description={<del>{JSON.stringify(e.value)}</del>} /> : null}
                    </>
                    )}
                </Col>
            </Row>
        </Modal>
    </>)
}

export default EditModel;