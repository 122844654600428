import React, { useCallback, useContext, useEffect, useState } from 'react';
import Layout from '../components/layout/Layout';
import { SceneDesignerContext } from '../contexts/SceneDesignerContext';
import './Designer.scss';
import { ReactComponent as UndoIcon } from '../assets/icons/undo.svg';
import { ReactComponent as RedoIcon } from '../assets/icons/redo.svg';
import { ReactComponent as ResetIcon } from '../assets/icons/reset.svg';
import { ReactComponent as ArrowLeftIcon } from '../assets/icons/arrow-left.svg';
import Submit from '../components/orders/submit';
import Moodboard from '../components/designer/MoodboardDesigner';
import { CartContext } from '../contexts/CartContext';
import { Link, useParams } from 'react-router-dom';
import { HexColorPicker } from "react-colorful";
import { ReactComponent as RenderIcon } from '../assets/icons/render.svg';
import { ReactComponent as ColorIcon } from '../assets/icons/color.svg';
import Drawer from '../components/layout/SideDrawer';
import { Confirm, Dialog } from '../components/layout/Dialog';
import Overlay from '../components/layout/Overlay';
import { ReactComponent as LoadingIcon } from '../assets/icons/loading.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { ProjectService, TemplateViewDTO } from '../openapi/requests';
import { ProjectStatus } from '../models/enums';
import { useAssetServiceGetAssetMetadataValues } from '../openapi/queries';
import CameraSelector from '../components/designer/CameraSelector';
import AnimationSelector from '../components/designer/AnimationSelector';
import { AuthContext } from '../contexts/AuthContext';
import ReactGA from "react-ga4";
import { Loader } from '../components/layout/elements/Loader';

const LoadingComponent = motion(LoadingIcon)


const ImageSelector: React.FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => {
    const { currentProject } = useContext(CartContext);

    return (
        <Overlay open={open} onClose={onClose} className='image-selection'>
            <div className='image-selection-content'>
                <div className='image-selection-header'>
                    <h2>Select images to Render</h2>
                </div>
                <CameraSelector showStillCameras={true} showAnimatedCameras={false} />
            </div>
            <div className='image-selection-footer'>
                {currentProject?.name}
            </div>
        </Overlay>
    )
};

const VideoSelector: React.FC<{ template?: TemplateViewDTO, open: boolean, onClose: () => void }> = ({ open, onClose, template }) => {
    const { currentProject } = useContext(CartContext);
    const { hasPermission } = useContext(AuthContext);

    return (
        <Overlay open={open} onClose={onClose} className='image-selection'>
            <div className='image-selection-content'>
                {(hasPermission("RenderVideo")) &&
                    <>
                        <div className='image-selection-header'>
                            <h2>Select videos to Render</h2>
                        </div>
                        <AnimationSelector />
                    </>
                }
            </div>
            <div className='image-selection-footer'>
                {currentProject?.name}
            </div>
        </Overlay>
    )
};



const Designer: React.FC = () => {
    const { currentProject, currentOrder, setCurrentProject, initializing: cartLoading, working: cartWorking, submitting: cartSubmitting, isLimitExceeded, setLimitExceeded } = useContext(CartContext);
    const { undo, redo, reset, canRedo, canUndo, configuration, setConfiguration, loading: sceneLoading, selectedBrand, setSelectedBrand, template } = useContext(SceneDesignerContext);
    const { hasPermission } = useContext(AuthContext);
    const { data: brands } = useAssetServiceGetAssetMetadataValues({ name: 'brand' });
    const [renderDialogOpen, setRenderDialogOpen] = useState(false);
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [imagePickerOpen, setImagePickerOpen] = useState(false);
    const [animationPickerOpen, setAnimationPickerOpen] = useState(false);
    const [limitDialogOpen, setLimitDialogOpen] = useState(false);

    let { projectID } = useParams();

    const handleUndo = useCallback(() => {
        ReactGA.event('undo', {});
        undo();
    }, [undo]);

    const handleRedo = useCallback(() => {
        ReactGA.event('redo', {});
        redo();
    }, [redo]);

    const handleReset = useCallback(() => {
        ReactGA.event('reset', {});
        reset();
    }, [reset]);

    const handleBrandChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        ReactGA.event('filter_by_brand', {brand: e.target.value});
        setSelectedBrand(e.target.value);
    }, [setSelectedBrand]);

    const handleOpenColorPicker = useCallback(() => {
        ReactGA.event('open_dialog', {dialog: 'colorpicker'});
        setColorPickerOpen(true);
    }, []);

    const handleOpenImageSelection = useCallback(() => {
        ReactGA.event('open_dialog', {dialog: 'imageselector'});
        setImagePickerOpen(true);
    }, []);

    const handleOpenAnimationSelection = useCallback(() => {
        ReactGA.event('open_dialog', {dialog: 'animationselector'});
        setAnimationPickerOpen(true);
    }, []);

    useEffect(() => {
        let id = parseInt(projectID ?? '-1');
        if (currentProject?.id !== id && id > 0) {
            ProjectService.getProject1(id).then(project => {
                if (project) {
                    setCurrentProject(project);
                }
            });
        }
    }, [currentProject?.id, projectID, setCurrentProject]);

    useEffect(() => {
        if (isLimitExceeded) {
            setLimitDialogOpen(true)
        }
    }, [isLimitExceeded])

    const handleColorChange = useCallback((color: string) => {
        ReactGA.event('change_moodboard_color', {});
        let config = { ...configuration, moodboard: { ...configuration.moodboard, backgroundColor: color } };
        setConfiguration(config, true)
    }, [configuration, setConfiguration]);

    return (<Layout className='designer-page'>
        {cartSubmitting && <Loader></Loader>}
        <header>
            <div>
                <button disabled={!canUndo} onClick={handleUndo}><UndoIcon /></button>
                <button disabled={!canRedo} onClick={handleRedo}><RedoIcon /></button>
                <Confirm onConfirm={handleReset} text='You are about to reset all the changes to this scene.' title='Reset Scene'><button><ResetIcon /></button></Confirm>
            </div>
            <div>

            </div>
            <div>
                <select className='button' value={selectedBrand} onChange={handleBrandChange}>
                    <option value={''}>Select a brand</option>
                    {brands?.map(e => <option key={e} value={e}>{e}</option>)}
                </select>

                <button className='icon' onClick={handleOpenColorPicker}><ColorIcon /></button>

                {((template?.scene.cameras.some(e => e.isAnimated) && hasPermission("RenderAnimatedCamera")) || ((template?.scene.animations.length ?? 0) > 0 && hasPermission("RenderVideo"))) &&
                    <button className='create' onClick={handleOpenAnimationSelection}>
                        Video selection ...
                    </button>
                }

                {template?.scene.cameras.some(e => !e.isAnimated) &&
                    <button className='create' onClick={handleOpenImageSelection}>
                        Image selection ...
                    </button>
                }

                <button className='create' onClick={() => setRenderDialogOpen(true)}>Create<RenderIcon /></button>
            </div>
        </header>
        <div className='designer-page-container'>
            <AnimatePresence>
                {(cartLoading || sceneLoading || cartWorking) && <LoadingComponent
                    className={'loading'}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={'loading'} />}
            </AnimatePresence>

            <AnimatePresence>
                {((!cartLoading && !sceneLoading && !cartWorking) && currentProject === undefined) && <motion.div
                    className={'no-project'}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={'no-project'}>
                    Project not found
                </motion.div>}
            </AnimatePresence>

            <Moodboard />
        </div>
        <footer>
            <div>
                <Link to={'/create/projects'}>
                    <ArrowLeftIcon /> Projects
                </Link>
                <span>
                    {currentProject?.name}
                </span>
            </div>
            <div>
            </div>
        </footer>        
        
        <Submit isOpen={renderDialogOpen} onClose={() => setRenderDialogOpen(false)} />

        <Drawer className='colorpicker' open={colorPickerOpen} onClose={() => setColorPickerOpen(false)} title='Select color'>
            <HexColorPicker
                color={configuration.moodboard.backgroundColor}
                onChange={handleColorChange} />
        </Drawer>

        <ImageSelector open={imagePickerOpen} onClose={() => setImagePickerOpen(false)} />
        <VideoSelector template={template} open={animationPickerOpen} onClose={() => setAnimationPickerOpen(false)} />

        {currentOrder?.isRendering && <div className='rendering-blocker'>
            <div>
                <p>You will be able to make changes after the {currentOrder.status === ProjectStatus.RenderingPreview ? 'preview' : 'final'} render is complete</p>
                <h1>In Progress</h1>
                <Link className='button primary' to={'/create/projects'}>GO TO PROJECT OVERVIEW</Link>
            </div>
        </div>}

        <Dialog open={limitDialogOpen} className='confirm'>
            <h1>Renderlimit Exceeded</h1>
            <p className='confirm-body'>
                You have exceeded the amount of renders available to you per order, please modify your order.
            </p>
            <div className='confirm-footer'>
                <button className='confirm' onClick={() => { setLimitExceeded(false); setLimitDialogOpen(false) }}>Ok</button>
            </div>
        </Dialog>

    </Layout>)
};

export default Designer;